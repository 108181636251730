import React, {
  useState
} from 'react'
import {
  Formik
} from 'formik'
import * as Yup from 'yup'
import {
  Input,
  ActionButton,
  Select,
  TextArea,
  Alert
} from '../../../../../components'
import {
  RegistrasiKaryawanApi
} from '../../../../../api'

const FormDataBank = ({ dataKaryawan, dataBank }) => {
  const [showAlert, setShowAlert] = useState({
    show: false,
    text: '',
    variant: 'primary'
  })

  const checkDuplicate = async (field, value) => {
    const params = {}
    params[field] = value;

    if (dataKaryawan[field] === value) return true;

    return await RegistrasiKaryawanApi.checkDuplicateNama(params)
      .then(() => true)
      .catch(() => false)
  }

  const formInitialValues = {
    id_karyawan: dataKaryawan.id_karyawan,
    kode_bank: dataKaryawan.kode_bank,
    no_rekening: dataKaryawan.no_rekening,
    atas_nama: dataKaryawan.atas_nama,
    cabang: dataKaryawan.cabang
  }

  const formValidationSchema = Yup.object().shape({
    kode_bank: Yup.string().required('Pilih bank').nullable(),
    atas_nama: Yup.string().required('Masukan atas nama rekening').nullable(),
    no_rekening: Yup.string()
      .nullable()
      .required('Masukan nomor rekening')
      .test('checkDuplicate', 'Nomor rekening sudah didaftarkan', async (value) => await checkDuplicate('no_rekening', value)),
  })

  const formSubmitHandler = (values, { setSubmitting }) => {
    RegistrasiKaryawanApi.updateDataBank(values)
      .then(() => {
        setShowAlert({
          show: true,
          variant: 'primary',
          text: 'Ubah data sukses!'
        })
      }).catch(err => {
        setShowAlert({
          show: true,
          variant: 'danger',
          text: `Ubah data gagal! (${err})`
        })
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldValue }) => (
        <form
          onSubmit={handleSubmit}
          className="py-2"
        >
          <Alert
            show={showAlert.show}
            showCloseButton={true}
            text={showAlert.text}
            variant={showAlert.variant}
            onClose={() => setShowAlert({
              ...showAlert,
              show: false
            })}
          />
          <Select
            label="Nama Bank"
            name="kode_bank"
            defaultValue={values.kode_bank}
            error={errors.kode_bank && touched.kode_bank && true}
            errorText={errors.kode_bank}
            onChange={handleChange}
          >
            <option value="">Pilih bank</option>
            {dataBank.map((val, index) => (
              <option
                key={index}
                value={val.kode_bank}
              >
                {val.nama_bank}
              </option>
            ))}
          </Select>
          <Input
            type="text"
            label="No. rekening"
            placeholder="Masukan nomor rekening"
            name="no_rekening"
            value={values.no_rekening}
            onChange={(e) => {
              const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
              setFieldValue('no_rekening', onlyNumber)
            }}
            error={errors.no_rekening && true}
            errorText={errors.no_rekening}
          />
          <Input
            type="text"
            label="Atas Nama"
            placeholder="Masukan atas nama"
            name="atas_nama"
            value={values.atas_nama}
            error={errors.atas_nama && touched.atas_nama && true}
            errorText={errors.atas_nama}
            onChange={handleChange}
          />
          <TextArea
            type="text"
            label="Cabang"
            placeholder="Masukan cabang"
            name="cabang"
            value={values.cabang}
            error={errors.cabang && touched.cabang && true}
            errorText={errors.cabang}
            onChange={handleChange}
          />
          <div className="d-flex justify-content-end mt-3">
            <ActionButton
              type="submit"
              variant="success"
              text="Ubah Data Perbankan"
              loading={isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

export default FormDataBank
