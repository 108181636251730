import {
  IoServerOutline,
  IoCashOutline,
  IoSpeedometerOutline,
  IoPersonCircleOutline,
  IoSchoolOutline,
  IoPeopleOutline,
  IoKeyOutline,
  IoCalendarOutline,
  IoEaselOutline,
  IoInformationOutline,
  IoCheckboxOutline,
  IoDocumentOutline,
} from "react-icons/io5";

// IMPORT LOGO
import Logo from "../assets/image/LogoPuspa.jpg";

// IMPORT PAGE
import {
  // MASTER JABATAN
  Hirarki,
  UnitOrganisasi,
  Pangkat,
  Grade,
  Jabatan,

  // MASTER KARYAWAN
  LokasiKerja,
  JenisKaryawan,
  StatusKaryawan,
  DetailStatusKaryawan,
  RegistrasiKaryawan,
  DetailKaryawan,
  TambahKaryawan,
  UbahKaryawan,

  // MASTER HAK AKSES
  HakAksesAplikasi,
  HakAksesApproval,
  HakAksesDivisi,
  HakAksesDashboard,
} from "../pages/HumanResource";

export default {
  LOGO: Logo,
  MODUL: "Admin HRD",

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: "Dashboard",
      link: "/",
      type: "menu",
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ["HRDA", "SUPA"],
    },
    {
      text: "Master Jabatan",
      type: "dropdown",
      icon: <IoSchoolOutline />,
      hak: ["HRDA", "SUPA"],
      menu: [
        {
          text: "Hirarki Unit Organisasi",
          link: "/human-resource/master/jabatan/hirarki",
          hak: ["HRDA", "SUPA", "HRDA_MAS_HUO"],
        },
        {
          text: "Unit Organisasi",
          link: "/human-resource/master/jabatan/unit-organisasi",
          hak: ["HRDA", "SUPA", "HRDA_MAS_UOR"],
        },
        {
          text: "Pangkat",
          link: "/human-resource/master/jabatan/pangkat",
          hak: ["HRDA", "SUPA", "HRDA_MAS_PKT"],
        },
        {
          text: "Grade",
          link: "/human-resource/master/jabatan/grade",
          hak: ["HRDA", "SUPA", "HRDA_MAS_GRD"],
        },
        {
          text: "Jabatan",
          link: "/human-resource/master/jabatan/jabatan",
          hak: ["HRDA", "SUPA", "HRDA_MAS_JBT"],
        },
      ],
    },
    {
      text: "Master Pekerja",
      type: "dropdown",
      icon: <IoPeopleOutline />,
      hak: ["HRDA", "SUPA"],
      menu: [
        {
          text: "Lokasi Kerja",
          link: "/human-resource/master/karyawan/lokasi-kerja",
          hak: ["HRDA", "SUPA", "HRDA_MAS_LKK"],
        },
        {
          text: "Jenis Pekerja",
          link: "/human-resource/master/karyawan/jenis",
          hak: ["HRDA", "SUPA", "HRDA_MAS_JPK"],
        },
        {
          text: "Status Pekerja",
          link: "/human-resource/master/karyawan/status",
          hak: ["HRDA", "SUPA", "HRDA_MAS_STP"],
        },
        {
          text: "Detail Status Pekerja",
          link: "/human-resource/master/karyawan/detail-status",
          hak: ["HRDA", "SUPA", "HRDA_MAS_DSP"],
        },
        {
          text: "Pekerja",
          link: "/human-resource/master/karyawan/registrasi",
          hak: ["HRDA", "SUPA", "HRDA_MAS_PKJ"],
        },
      ],
    },
    {
      text: "Hak Akses",
      type: "dropdown",
      icon: <IoKeyOutline />,
      hak: ["HRDA", "SUPA"],
      menu: [
        {
          text: "Hak Akses Modul",
          link: "/human-resource/master/hak/divisi",
          hak: ["HRDA", "SUPA", "HRDA_TRN_HAM"],
        },
        {
          text: "Hak Akses Approval",
          link: "/human-resource/master/hak/approval",
          hak: ["HRDA", "SUPA", "HRDA_TRN_HAA"],
        },
        // {
        //   text: "Hak Akses Aplikasi",
        //   link: "/human-resource/master/hak/aplikasi",
        //   hak: ["HRDA", "SUPA", "HRDA_HAK_APP"],
        // },
        // {
        //   text: "Hak Akses Dashboard",
        //   link: "/human-resource/master/hak/dashboard",
        //   hak: ["HRDA", "SUPA", "HRDA_HAK_DAS"],
        // },
      ],
    },
    // {
    //   text: "Laporan",
    //   type: "dropdown",
    //   icon: <IoDocumentOutline />,
    //   hak: ["HRDA", "SUPA"],
    //   menu: [],
    // },
    // {
    //   text: "Absensi",
    //   link: "/human-resource/absensi",
    //   type: "menu",
    //   exact: true,
    //   icon: <IoCheckboxOutline />,
    //   hak: ["HRDA", "SUPA"],
    // },
    // {
    //   text: "Training",
    //   link: "/human-resource/training",
    //   type: "menu",
    //   exact: true,
    //   icon: <IoEaselOutline />,
    //   hak: ["HRDA", "SUPA"],
    // },
    // {
    //   text: "Info Karyawan",
    //   link: "/human-resource/info-karyawan",
    //   type: "menu",
    //   exact: true,
    //   icon: <IoInformationOutline />,
    //   hak: ["HRDA", "SUPA"],
    // },
    // {
    //   text: "Agenda",
    //   link: "/human-resource/agenda",
    //   type: "menu",
    //   exact: true,
    //   icon: <IoCalendarOutline />,
    //   hak: ["HRDA", "SUPA"],
    // },
    // {
    //   text: "Profil",
    //   type: "dropdown",
    //   icon: <IoPersonCircleOutline />,
    //   hak: ["ANG"],
    //   menu: [
    //     {
    //       text: "Akun Saya",
    //       link: "/profil",
    //       hak: ["ANG"],
    //     },
    //     {
    //       text: "Ubah Akun",
    //       link: "/profil/ubah",
    //       hak: ["ANG"],
    //     },
    //     {
    //       text: "Ganti Password",
    //       link: "/profil/ganti-password",
    //       hak: ["ANG"],
    //     },
    //   ],
    // },
  ],

  ROUTES: [
    {
      exact: true,
      path: "/human-resource/master/jabatan/hirarki",
      page: Hirarki,
      hak: ["HRDA", "SUPA", "HRDA_MAS_HUO"],
    },
    {
      exact: true,
      path: "/human-resource/master/jabatan/unit-organisasi",
      page: UnitOrganisasi,
      hak: ["HRDA", "SUPA", "HRDA_MAS_UOR"],
    },
    {
      exact: true,
      path: "/human-resource/master/jabatan/pangkat",
      page: Pangkat,
      hak: ["HRDA", "SUPA", "HRDA_MAS_PKT"],
    },
    {
      exact: true,
      path: "/human-resource/master/jabatan/grade",
      page: Grade,
      hak: ["HRDA", "SUPA", "HRDA_MAS_GRD"],
    },
    {
      exact: true,
      path: "/human-resource/master/jabatan/jabatan",
      page: Jabatan,
      hak: ["HRDA", "SUPA", "HRDA_MAS_JBT"],
    },

    {
      exact: true,
      path: "/human-resource/master/karyawan/lokasi-kerja",
      page: LokasiKerja,
      hak: ["HRDA", "SUPA", "HRDA_MAS_LKK"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/jenis",
      page: JenisKaryawan,
      hak: ["HRDA", "SUPA", "HRDA_MAS_JPK"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/status",
      page: StatusKaryawan,
      hak: ["HRDA", "SUPA", "HRDA_MAS_STP"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/detail-status",
      page: DetailStatusKaryawan,
      hak: ["HRDA", "SUPA", "HRDA_MAS_DSP"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/registrasi",
      page: RegistrasiKaryawan,
      hak: ["HRDA", "SUPA", "HRDA_MAS_PKJ"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/tambah",
      page: TambahKaryawan,
      hak: ["HRDA", "SUPA", "HRDA_MAS_PKJ"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/:id",
      page: DetailKaryawan,
      hak: ["HRDA", "SUPA", "HRDA_MAS_PKJ"],
    },
    {
      exact: true,
      path: "/human-resource/master/karyawan/ubah/:id",
      page: UbahKaryawan,
      hak: ["HRDA", "SUPA", "HRDA_MAS_PKJ"],
    },

    // {
    //   exact: true,
    //   path: "/human-resource/master/hak/aplikasi",
    //   page: HakAksesAplikasi,
    //   hak: ["HRDA_HAK_APP", "SUPA"],
    // },
    {
      exact: true,
      path: "/human-resource/master/hak/approval",
      page: HakAksesApproval,
      hak: ["HRDA", "SUPA", "HRDA_TRN_HAA"],
    },
    {
      exact: true,
      path: "/human-resource/master/hak/divisi",
      page: HakAksesDivisi,
      hak: ["HRDA_TRN_HAM", "SUPA"],
    },
    // {
    //   exact: true,
    //   path: "/human-resource/master/hak/dashboard",
    //   page: HakAksesDashboard,
    //   hak: ["HRDA_HAK_DAS", "SUPA"],
    // },
  ],
};
